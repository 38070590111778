@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*@import url('/stylesheets/okta-theme.css'); !*shows as error in IDE but resolves to public folder *!*!*/

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }

.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

.material-icons.md-light { color: rgba(255, 255, 255, 1); }

.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }

.MuiIconButton-colorInherit { color: rgba(0, 0, 0, 0.54);}

.MuiIcon-root { color: rgba(0, 0, 0, 0.54);}

/*.MuiToolbar-regular { min-height: 0px }*/

.MuiToolbar-regular {
  /*min-height: 24px;*/
}

.MuiInputBase-input {
  padding-top: 20px;
}
